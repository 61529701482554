<template>
  <CContainer>
    <nav-bar v-bind:headerName="headerName"></nav-bar>
    <hr class="mt-0" />

    <img :src="qrcode" class="img-fluid" alt="" />
    <h6 class="text-center mt-3">ยอดชำระ {{ amount }} บาท</h6>
    <a :href="qrcode" class="mt-3 btn btn-outline-dark btn-block">
      <CIcon name="cil-camera" size="xl"></CIcon> บันทึกรูปภาพ
    </a>
  </CContainer>
</template>

<script>
import util from '@/util/util'
import payment from '@/services/payment'
import NavBar from '../menu/NavBar'

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      qrcode: '',
      headerName: 'พร้อมเพย์',
    }
  },
  computed: {
    amount() {
      let totalAmount = sessionStorage.getItem('amount')
      return util.convertCurrency(Number(totalAmount))
    },
  },
  mounted() {
    this.promptpay()
  },
  methods: {
    promptpay() {
      let amount = sessionStorage.getItem('amount')
      let totalAmount = Number(amount) * 100

      let data = { sourceType: 'promptpay', amount: totalAmount }

      payment({
        url: '/omise/payment/v1.0/test/promptpay',
        data: data,
        method: 'post',
      }).then((res) => {
        console.log(res)
        if (res.data.error.code === 0) {
          sessionStorage.setItem('chargeId', res.data.data.id)
          this.qrcode = res.data.data.uri
          //window.location.replace(res.data.data.uri)
        }
      })
    },
  },
}
</script>
